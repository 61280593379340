/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Praise&display=swap");

/* Existing styles */
body {
  margin: 0;
  font-family: "merienda", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply overflow-x-hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-slideOutLeft {
  animation: slideOutLeft 0.3s forwards;
}

.animate-slideInRight {
  animation: slideInRight 0.3s forwards;
}

.font-merienda {
  font-family: "Merienda", sans-serif;
}